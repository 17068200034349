import React, { Component } from 'react';
//import '../css/Contact.css';

class Contact extends Component {
    render() {
        return (
            <div className="section">
                <h1>Contact Me</h1>
            </div>
        );
    }
}

export default Contact;

