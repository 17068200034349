import React, { Component } from 'react';
//import '../css/About.css';

class About extends Component {
    render() {
        return (
            <div className="section">
                <h1>About Me</h1>
            </div>
        );
    }
}

export default About;

