import React, { Component } from 'react';
//import '../css/Projects.css';

class Projects extends Component {
    render() {
        return (
            <div className="section">
                <h1>Projects</h1>
            </div>
        );
    }
}

export default Projects;

